import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthChecker from "./authChecker";
import DashboardWrapper from "Layout";
import {
  Aboutus,
  Policy,
  Certificate,
  GeneralProgram,
  Home,
  MyCardPublic,
  ProProgram,
  Signin,
  PaymentPage,
  Track,
  Courses,
  Partner,
  Lissun,
  JobAssurance,
  CopyWriting,
  Card,
  FinanceProProgram,
  ReferralProgram,
  // AIProgram
} from "Pages";
import Loader from "Components/Loader";
import Header from "Layout/Header/Header";
import ScreenWrapper from "Layout/Screen";
import Footer from "Layout/Footer";
import NotFound from "Components/NotFound";
// import {useDomain} from "Context/domainContext"

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
  allow12thPass: boolean
  allDomain?: boolean;
}

function Routers() {
  // const {domain} = useDomain()
  console.log('domain-', window.location.origin);
  
  function ViewWithHeaderAndFooter({ elem }: any) {
    const sipNew = window.location.pathname.includes("register") || window.location.pathname === '/pro';
    console.log(sipNew);

    const color = sipNew ? "#971eff" : "#14081e";
    return (
      <ScreenWrapper>
        <Header bgColor={color} sipNew={sipNew} />
        {elem}
        <Footer />
      </ScreenWrapper>
    );
  }
  function ViewWithHeader({ elem }: any) {
    const sipNew =
      window.location.pathname.includes("register") ||
      window.location.pathname === '/pro';
    const color = sipNew ? "#971eff" : "#14081e";
    return (
      <ScreenWrapper>
        <Header bgColor={color} />
        {elem}
      </ScreenWrapper>
    );
  }
  const routes: RouteType[] = [
    {
      path: "/user/dashboard/:menu",
      component: <DashboardWrapper />,
      name: "General Dashboard",
      protected: true,
      allow12thPass: false,    
    },
    {
      path: "/program/dashboard/:menu",
      component: <DashboardWrapper />,
      name: "Program Dashboard",
      protected: true,
      allow12thPass: false,
    },
    {
      path: "/certificate/:type/:id",
      component: <ViewWithHeader elem={<Certificate />} />,
      name: "Certificate",
      protected: false,
      allow12thPass: false,
    },
    {
      path: "/aboutus",
      component: <ViewWithHeaderAndFooter elem={<Aboutus />} />,
      name: "Certificate",
      protected: false,
      allow12thPass: false,    
    },
    {
      path: "/quiz/:id",
      component: <ViewWithHeaderAndFooter elem={<Partner />} />,
      name: "Partner",
      protected: false,
      allow12thPass: false,
    },
    {
      path: "/policy/:type",
      component: <ViewWithHeaderAndFooter elem={<Policy />} />,
      name: "Certificate",
      protected: false,
      allow12thPass: false,
    },
    {
      path: "/alpha/track/:id",
      component: <ViewWithHeader elem={<Track />} />,
      name: "Alpha Tracing",
      protected: false,
      allow12thPass: false,
    },
    {
      path: "/sip/payment/:id",
      component: <ViewWithHeader elem={<PaymentPage />} />,
      name: "Alpha Tracing",
      protected: false,
      allow12thPass: false,
      allDomain:true
    },
    {
      path: "/signin",
      component: <Signin />,
      name: "Signin Screen",
      protected: false,
      allow12thPass: false,
    },
    {
      path: "/",
      component: <ViewWithHeaderAndFooter elem={<Home />} />,
      name: "Home Page",
      protected: false,
      allow12thPass: false,
    },
    {
      path: "/",
      component: <ViewWithHeaderAndFooter elem={<JobAssurance />} />,
      name: "Home Page",
      protected: false,
      allow12thPass: true,    
    },
    {
      path: "/:id",
      component: <MyCardPublic />,
      name: "Card Public Page",
      protected: false,
      allow12thPass: false,
    },
    {
      path: "/sip/register",
      component: <ViewWithHeaderAndFooter elem={<GeneralProgram />} />,
      name: "Sip Page New",
      protected: false,
      allow12thPass: false,
    },
    {
      path: "/pro",
      component: <ViewWithHeaderAndFooter elem={<ProProgram />} />,
      name: "Sip Page New",
      protected: false,
      allow12thPass: false,
    },
    {
      path: "/job",
      component: <ViewWithHeaderAndFooter elem={<JobAssurance />} />,
      name: "JobAssurance New",
      protected: false,
      allow12thPass: false,
    },
    // {
    //   path: "/lissun",
    //   component: <ViewWithHeaderAndFooter elem={<Lissun />} />,
    //   name: "Lissun Landing Page",
    //   protected: false,
    //   allow12thPass: false,
    // },
    {
      path: "/program/PSYPRO",
      component: <ViewWithHeaderAndFooter elem={<Lissun />} />,
      name: "Lissun Landing Page",
      protected: false,
      allow12thPass: false,
    },
    // {
    //   path: "/program/AI",
    //   component: <ViewWithHeaderAndFooter elem={<AIProgram />} />,
    //   name: "AI Landing Page",
    //   protected: false,
    //   allow12thPass: false,
    // },
    {
      path: "/program/FMPRO",
      component: <ViewWithHeaderAndFooter elem={<FinanceProProgram />} />,
      name: "FinanceProProgram Landing Page",
      protected: false,
      allow12thPass: false,
    },
    {
      path: "/program/CWPRO",
      component: <ViewWithHeaderAndFooter elem={<CopyWriting />} />,
      name: "Copy Landing Page",
      allow12thPass: false,
      protected: false,
    },
    {
      path: "/referral",
      component: <ViewWithHeaderAndFooter elem={<ReferralProgram />} />,
      allow12thPass: false,
      name: "Referral Program Page",
      protected: false,
    },
    {
      path: "/card",
      allow12thPass: false,
      component: <ViewWithHeaderAndFooter elem={<Card />} />,
      name: "Card Landing Page",
      protected: false,
    },
    {
      path: "/program/:id",
      component: <ViewWithHeaderAndFooter elem={<Courses />} />,
      name: "Single Program Pages",
      protected: false,
      allow12thPass: false,
    },
  ];
console.log(process.env.REACT_APP_ENVIRONMENT);

  return (
    <Suspense
      fallback={
        <div>
          <Loader />
        </div>
      }
    >
      <BrowserRouter>
        {/* <AppLayout> */}
        <Routes>
          {routes.map((route, index) => (<React.Fragment>
            {window.location.origin?.toLocaleLowerCase().includes(process.env.REACT_APP_ENVIRONMENT==="local"?'localhost-':'12thpass')&& (route.allow12thPass || route.allDomain )&&<Route
              key={index}
              path={route.path}
              element={
                route.protected ? (
                  <AuthChecker>{route.component}</AuthChecker>
                ) : (
                  route.component
                )
              }
            />}
            {window.location.origin?.toLocaleLowerCase().includes(process.env.REACT_APP_ENVIRONMENT==="local"?'localhost':'youvah') && (!route.allow12thPass || route.allDomain)&&<Route
              key={index}
              path={route.path}
              element={
                route.protected ? (
                  <AuthChecker>{route.component}</AuthChecker>
                ) : (
                  route.component
                )
              }
            />}
            </React.Fragment>
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* </AppLayout> */}
      </BrowserRouter>
    </Suspense>
  );
}

export default React.memo(Routers);
